import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { profileMenuRouterLinks } from '@den-workspace/util-constants';

@Component({
  selector: 'den-workspace-menu-bottom',
  templateUrl: './menu-bottom.component.html',
  styleUrls: ['./menu-bottom.component.scss'],
})
export class MenuBottomComponent implements OnInit {
  profileMenuRouterLinks = profileMenuRouterLinks;
  fieldName = '';

  constructor(private route: Router) {}

  ngOnInit(): void {
    this.fieldName = this.route.url.split('/')[1];
    if (this.fieldName === 'virtual') {
      this.profileMenuRouterLinks = profileMenuRouterLinks.filter(
        (f) => f.name !== 'Virtual Interview'
      );
    }
    if (this.fieldName === 'cv') {
      this.profileMenuRouterLinks = profileMenuRouterLinks.filter(
        (f) => f.name !== 'Interactive CV'
      );
    }
    if (this.fieldName === 'live') {
      this.profileMenuRouterLinks = profileMenuRouterLinks.filter(
        (f) => f.name !== 'Live Interview'
      );
    }
  }
}
