// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from './../../../../../apps/den-app/src/environments/environment';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.credentials.clientId, // '85e95a78-c7c4-4e13-9566-418aae604485',
      authority:
        'https://login.microsoftonline.com/' + environment.credentials.tenantId, // 'e10de6a4-4a75-458c-b062-0bc063d5a370',
      redirectUri: environment.configuration.redirectUri, // 'http://localhost:4200',
      postLogoutRedirectUri: environment.configuration.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(
    environment.resources.GraphMe.resourceUri,
    environment.resources.GraphMe.resourceScopes
  );
  protectedResourceMap.set(
    environment.resources.Graph.resourceUri,
    environment.resources.Graph.resourceScopes
  );
  /*  protectedResourceMap.set(GRAPH_ENDPOINT, [`User.Read`]);
    protectedResourceMap.set('https://graph.microsoft.com/v1.0', [
    `User.Read`,
    `Calendars.ReadWrite`,
    'OnlineMeetings.ReadWrite.All',
    'OnlineMeetings.ReadWrite',
  ]); */

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    // authRequest: {
    //   scopes: ['user.read'],
    // },
  };
}
