import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'den-workspace-den-info',
  templateUrl: './den-info.component.html',
  styleUrls: ['./den-info.component.scss'],
})
export class DenInfoComponent {
  displayInfo = {
    title: 'Meeting Schedule has been send',
  };
}
