<div class="m-8">
  <form [formGroup]="durationForm">
    <div
      class="border-b-[0.736842px] border-solid border-[#E5E5E5] p-4"
      mat-dialog-title
    >
      <span class="text-xl font-semibold">Set Duration</span>
    </div>
    <mat-dialog-content>
      <div class="">
        <mat-form-field appearance="outline" class="w-full">
          <mat-select
            formControlName="duration"
            placeholder="Select Time Duration"
          >
            <mat-option
              *ngFor="let i of timeSeries"
              class="xs:text-xs md:text-sm font-semibold"
              [value]="i.value"
            >
              {{ i.hour }}
              <!-- k {{ i.value }} -->
            </mat-option>
          </mat-select>

          <mat-error
            class="mb-2 text-sm"
            *ngIf="controls.duration.hasError('required')"
          >
            Duration selection is <strong> mandatory</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="flex space-x-4">
        <button
          mat-dialog-close
          mat-stroked-button
          class="mr-5 btn re-btn"
          color="accent"
        >
          <span class="text-primary-crayola"> Close</span>
        </button>
        <button
          mat-flat-button
          class=""
          type="button"
          color="primary"
          (click)="onSet()"
          [disabled]="durationForm.invalid"
        >
          Add
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
