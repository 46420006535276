export const environment = {
  production: true,
  const_values: {
    snackbarDelayTime: 10000,
  },
  api_base_url: `https://api.domainexpertsnetwork.com/api/`,
  api_localhost_base_url: `https://dendev.rectitudecs.com/denapidev/api/`,
  api_microservices_endpoints: {
    authentication: 'Authentication/',
    user: 'User/',
    profile: 'Profile/',
    utils: 'Utils/',
    cv: 'CV/',
    liveInterview: 'Live/',
    search: 'Search/',
    virtualInterview: 'VirtualInterview/',
  },
  localhostUrls: {
    authentication: 'Authentication/',
    user: 'User/',
    profile: 'Profile/',
    utils: 'Utils/',
    cv: 'CV/',
    liveInterview: 'Live/',
    search: 'Search/',
    virtualInterview: 'VirtualInterview/',
  },
  credentials: {
    clientId: '401cf5cb-3bb8-4712-b936-b27a9d7c57e7', // '85e95a78-c7c4-4e13-9566-418aae604485',
    tenantId: 'e10de6a4-4a75-458c-b062-0bc063d5a370',
  },
  configuration: {
    redirectUri: 'https://domainexpertsnetwork.com/', // 'http://localhost:4200',
    postLogoutRedirectUri: 'https://domainexpertsnetwork.com/', // 'http://localhost:4200',
  },
  resources: {
    GraphMe: {
      resourceUri: `https://graph.microsoft.com/v1.0/me`,
      resourceScopes: [`User.Read`],
    },
    Graph: {
      resourceUri: `https://graph.microsoft.com/v1.0`,
      resourceScopes: [
        `User.Read`,
        `Calendars.ReadWrite`,
        `OnlineMeetings.ReadWrite.All`,
        `OnlineMeetings.ReadWrite`,
      ],
    },
  },
  linkedInCredentials: {
    response_type: 'code',
    clientId: '86ubpggyc09v1c',
    clientSecret: 'kNdUZzcjQOfQUL7v',
    redirectUri: 'https://domainexpertsnetwork.com/login', // 'http://localhost:4200/login',
    state: 987654321,
    scope: 'openid%20profile%20w_member_social%20email', // 'openid profile w_member_social email',
    resourceUri: `https://www.linkedin.com/oauth/v2/`,
  },
  assetsHTTPUrl: 'https://api.domainexpertsnetwork.com/usrdta/',
};
