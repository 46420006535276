import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'den-workspace-set-duration',
  templateUrl: './set-duration.component.html',
  styleUrls: ['./set-duration.component.scss'],
})
export class SetDurationComponent implements OnInit {
  durationForm = this.fb.group({
    duration: ['', [Validators.required]],
  });

  timeSeries: { hour: string; value: string }[] = [];

  get controls() {
    return this.durationForm.controls;
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SetDurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.calculateTimeSeries();
  }

  onSet() {
    this.dialogRef.close(this.controls.duration.value);
  }

  calculateTimeSeries() {
    const timeRanges = this.data.timeSeries.split(' to ');
    const startMoment = moment(timeRanges[0], 'HH:mm');
    const endMoment = moment(timeRanges[1], 'HH:mm');
    const durationInHours = endMoment.diff(startMoment, 'minutes', true); // 'hours', true);
    const timeSeries: { hour: string; value: string }[] = [];
    const currentTime = moment('00:05', 'HH:mm');
    for (let i = 0; i < durationInHours / 5; i++) {
      timeSeries.push({
        hour: `${
          currentTime.format('HH:mm').split(':')[0] === '00'
            ? ''
            : currentTime.format('HH:mm').split(':')[0] + ' hour'
        } ${
          currentTime.format('HH:mm').split(':')[1] === '00'
            ? ''
            : currentTime.format('HH:mm').split(':')[1] + ' minutes'
        }`, // currentTime.format('HH:mm'),
        value: currentTime.format('HH:mm'),
      });
      currentTime.add(5, 'minutes');
    }
    this.timeSeries = timeSeries;
    console.log(timeSeries);
  }

  convertMinutesToHoursAndMinutes(durationInMinutes: number): {
    hours: number;
    minutes: number;
  } {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return { hours, minutes };
  }
}
