<section>
  <div
    class="mx-3 my-8 p-6 bg-yellow-100 rounded-lg"
    *ngIf="rescheduleNote && rescheduleNote.rescheduleTime"
  >
    Reschedule note

    <div class="m-4 border-y-[0.5px] border-black">
      reschedule note given by the candidate
    </div>

    <div class="flex justify-between">
      <div>
        <i class="fa-regular fa-calendar fa-lg text-gray-700"></i>
        {{ rescheduleNote.rescheduleDate }}
      </div>
      <div>
        <i class="fa-regular fa-clock fa-lg text-gray-700"></i>
        {{ rescheduleNote.rescheduleTime }}
      </div>
    </div>
  </div>

  <form [formGroup]="denStandardTimeForm">
    <div class="text-xl font-medium m-4">Time Zone</div>
    <mat-form-field
      appearance="outline"
      color="primary"
      class="w-11/12 mx-4 text-sm font-semibold"
    >
      <input
        type="text"
        placeholder="Select TimeZone"
        matInput
        formControlName="timeZone"
        [matAutocomplete]="auto1"
        class=""
      />
      <mat-autocomplete
        #auto1="matAutocomplete"
        [displayWith]="filterTDisplayFn"
      >
        <mat-option
          *ngFor="let time1 of filteredStandardTimeZoneOptions"
          [value]="time1"
        >
          {{ time1.utc }} {{ time1.description }}
        </mat-option>
      </mat-autocomplete>
      <i class="fa-solid fa-caret-down fa-sm mr-3 text-gray-700" matSuffix></i>

      <mat-error
        class="mb-2 text-sm"
        *ngIf="controls.timeZone.hasError('required')"
      >
        Time Zone is <strong>required</strong>
      </mat-error>

      <mat-error
        class="mb-2 text-sm"
        *ngIf="controls.timeZone?.hasError('invalidAutocompleteObject')"
      >
        Time Zone <strong>is invalid. </strong>
      </mat-error>
    </mat-form-field>
    <div class="m-6">
      <mat-card class="xs:m-2 md:m-4 sm:w-8/12 md:w-10/12">
        <mat-calendar
          [(selected)]="selectedDate"
          (selectedChange)="updateFormDate($event)"
        >
        </mat-calendar
      ></mat-card>
    </div>
    <div class="mx-3">
      <button
        class="mr-8 my-2 float-right text-[#FF5263] font-semibold"
        (click)="onReset()"
        type="button"
      >
        Reset
      </button>
    </div>
  </form>
</section>
