<div class="my-4 mx-8">
  <div
    class="text-xl font-semibold border-b-[0.736842px] border-solid border-[#E5E5E5] p-4"
  >
    Delete Permanently?
  </div>

  <div class="rounded-xl mat-dialog-content p-4">
    If you delete this, you won't be able to recover it. Do you want to delete?
  </div>

  <div class="m-4 float-right">
    <button
      mat-stroked-button
      [mat-dialog-close]="true"
      class="float-right mr-8"
      type="button"
      class="mr-10"
      color="primary"
    >
      Close
    </button>

    <button
      mat-flat-button
      class="mr-10"
      type="button"
      color="primary"
      (click)="onDelte()"
    >
      Yes, delete
    </button>
  </div>
</div>
