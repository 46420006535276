import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[noSpace]',
})
export class NoSpaceDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const phoneNumber = input.value.replace(/\s/g, '');

    this.renderer.setProperty(input, 'value', phoneNumber);
  }

  // @HostListener('input', ['$event']) onInputChange(event: any) {
  //   const initialValue = this.el.nativeElement.value;
  //   const newValue = initialValue.replace(/\s/g, ''); // Remove spaces
  //   if (initialValue !== newValue) {
  //     this.el.nativeElement.value = newValue;
  //     event.stopPropagation();
  //   }
  // }
}
