<div class="bg-not-found bg-fixed min-h-screen self-center text-center">
  <h1 class="text-3xl font-extrabold sm:text-5xl text-rose-300 p-6 text-center">
    <img src="../../../../assets/images/denlogo.png" class="w-52 p-6" />
    Our website is under construction.
    <br />
    We`ll be here soon with new awesome design.
  </h1>
  <button
    class="bg-primary-crayola p-4 rounded-lg text-white cursor-pointer"
    (click)="back()"
    type="button"
    matRipple
    matRippleColor="primary"
    [matRippleCentered]="true"
  >
    <i class="fa-solid fa-chevron-left mr-4"></i>
    Go Back
  </button>
</div>
