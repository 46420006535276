export function utilModels(): string {
  return 'util-models';
}

export interface AuditableProperties {
  createdBy?: string;
  created?: string;
  lastModifiedBy?: string;
  lastModified?: string;
  isInActive?: boolean;
}
export interface RescheduleNoteResponse {
  userId?: string;
  userName?: string;
  reasonReschedule: string;
  rescheduleDate: string;
  rescheduleTime: string;
}
export const initialRescheduleNoteResponse: RescheduleNoteResponse = {
  userId: '',
  userName: '',
  reasonReschedule: '',
  rescheduleDate: '',
  rescheduleTime: '',
};
