import { Component } from '@angular/core';

@Component({
  selector: 'den-workspace-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'DEN-APP';
}
