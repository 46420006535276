<!-- Menu navigate -->
<div class="">
  <div class="flex justify-start space-x-4">
    <div
      class="px-10 py-6 text-center rounded-lg bg-rose-50 cursor-pointer shadow-sm hover:shadow-xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
      *ngFor="let link of profileMenuRouterLinks"
      matRipple
      matRippleColor="primary"
      [matRippleCentered]="true"
      [routerLink]="link.routeLink"
      routerLinkActive="active"
    >
      <a class="">
        <i class="fa-xl text-primary-crayola {{ link.icon }}"></i>
        <div class="font-medium">
          {{ link.name }}
        </div>
      </a>
    </div>
  </div>
</div>
<!-- end Menu navigate -->
