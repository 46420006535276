/* eslint-disable @nx/enforce-module-boundaries */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TimeZoneResponse } from 'apps/den-app/src/app/base/models/user-response.model';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import {
  RescheduleNoteResponse,
  initialRescheduleNoteResponse,
} from '@den-workspace/util-models';
import { defaultTimeZone } from '@den-workspace/util-constants';
import * as moment from 'moment';

@Component({
  selector: 'den-workspace-den-standard-time',
  templateUrl: './den-standard-time.component.html',
  styleUrls: ['./den-standard-time.component.scss'],
})
export class DenStandardTimeComponent
  implements OnDestroy, OnChanges, AfterViewInit
{
  private alive = new Subject<void>();
  @Input() selectedDate = new Date();
  @Input() standardTimeZone: TimeZoneResponse[] = [];
  @Input() rescheduleNote: RescheduleNoteResponse =
    initialRescheduleNoteResponse;
  @Output() dateChange = new EventEmitter<{
    date: string;
    timeZone: string;
    utc: string;
  }>();

  denStandardTimeForm = this.fb.group({
    timeZone: [
      defaultTimeZone,
      [Validators.required, this.validateAutoComplete.bind(this)],
    ],
    date: [new Date(), Validators.required],
  });

  filteredStandardTimeZoneOptions: TimeZoneResponse[] = [];

  get controls() {
    return this.denStandardTimeForm.controls;
  }

  constructor(private fb: FormBuilder) {}

  ngAfterViewInit(): void {
    this.controls.date.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe((valueForm) => {
        this.dateChange.emit({
          /* date: moment(valueForm.date).format('MM-DD-YYYY'),
        timeZone:
          valueForm.timeZone?.utc + ' ' + valueForm.timeZone?.description,
        utc: valueForm.timeZone?.utc as string, */
          date: moment(valueForm).format('MM-DD-YYYY'),
          timeZone:
            this.controls.timeZone.value?.utc +
            ' ' +
            this.controls.timeZone.value?.description,
          utc: this.controls.timeZone.value?.utc as string,
        });
      });

    this.controls.timeZone.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe((value) => {
        const t = typeof value === 'string' ? value : value ? value.utc : '';
        this.filteredStandardTimeZoneOptions = this._filter(t || '');
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const property of Object.keys(changes)) {
      switch (property) {
        case 'standardTimeZone':
          this.filteredStandardTimeZoneOptions = this.standardTimeZone;
          break;
        case 'selectedDate':
          if (
            new Date(this.selectedDate).getTime() !==
            new Date(this.denStandardTimeForm.value.date as Date).getTime()
          ) {
            // Update logic here
            this.denStandardTimeForm.patchValue({
              timeZone: defaultTimeZone,
              date: new Date(this.selectedDate),
            });
          }

          break;
        default:
          break;
      }
    }
  }

  onReset() {
    this.denStandardTimeForm.patchValue({
      timeZone: defaultTimeZone,
      date: new Date(),
    });
  }

  /*
  filterT() {
    this.filteredStandardTimeZoneOptions =
      this.controls.timeZone.valueChanges.pipe(
        startWith(''),
        map((value) => {
          console.log(value, typeof value);
          return typeof value === 'string' ? value : value ? value.utc : '';
        }),
        map((value) => this._filter(value || ''))
      );
  } */

  filterTDisplayFn(code: TimeZoneResponse): string {
    return code ? code.utc + ' ' + code.description : '';
  }

  private _filter(value: string): TimeZoneResponse[] {
    const filterValue = value.toLowerCase();
    return this.standardTimeZone.filter(
      (option) =>
        option.utc.toLowerCase().includes(filterValue) ||
        option.country.toLowerCase().includes(filterValue) ||
        option.description.toLowerCase().includes(filterValue)
    );
  }

  validateAutoComplete(control: AbstractControl) {
    if (typeof control.value === 'string') {
      return { invalidAutocompleteObject: true };
    }
    return null;
  }

  updateFormDate(value: any) {
    this.denStandardTimeForm.get('date')?.patchValue(value);
  }

  ngOnDestroy(): void {
    this.alive.next(undefined);
    this.alive.complete();
  }
}
