import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import * as moment from 'moment';

export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export class FormErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

export function generateTimeArray(): string[] {
  const times: string[] = [];
  const currentTime = moment('00:05', 'HH:mm');

  while (currentTime.isSameOrBefore(moment('23:59', 'HH:mm'))) {
    times.push(currentTime.format('HH:mm'));
    currentTime.add(5, 'minutes');
  }

  return times;
}
