<div class="lg:h-52 xxs:h-52 lg:w-96 xxs:w-56 lg:p-3 xxs:p-1">
  <div>
    <i class="fa-solid fa-times text-[#5F666F] float-right" mat-dialog-close>
    </i>
  </div>
  <div
    class="p-10 border-green-100 border-2 bg-green-100 rounded-full h-20 w-20 lg:mx-36 xxs:mx-16 lg:my-8 xxs:my-2 flex justify-center"
  >
    <i class="fa-solid fa-circle-check fa-2xl text-center text-[#27d9aa]"></i>
  </div>
  <div class="text-xl text-center font-medium text-[#49505B]">
    {{ displayInfo.title }}
  </div>
</div>
