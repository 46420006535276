import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monthName',
})
export class MonthNamePipe implements PipeTransform {
  transform(monthNumber: number | string): string {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    if (Number(monthNumber) >= 1 && Number(monthNumber) <= 12) {
      return months[Number(monthNumber) - 1];
    } else {
      return 'Invalid Month';
    }
  }
}
