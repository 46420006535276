import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxPaginationModule } from 'ngx-pagination';
import { SwiperModule } from 'swiper/angular';
import { DenInfoComponent } from './den-info/den-info/den-info.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DenStandardTimeComponent } from './den-standard-time/den-standard-time/den-standard-time.component';
import { SearchPlaceholderListComponent } from './search-placeholder-list/search-placeholder-list.component';
import { DefaultPlaceholderComponent } from './default-placeholder/default-placeholder.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { OverlayModule } from '@angular/cdk/overlay';
import { MenuBottomComponent } from './menu-bottom/menu-bottom.component';
import { RouterModule } from '@angular/router';
import { SnackbarAlertComponent } from './snackbar-alert/snackbar-alert.component';
import { SetDurationComponent } from './set-duration/set-duration/set-duration.component';
import { NoSpaceDirective } from './directives/no-space.directive';
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { MonthNamePipe } from './pipe/month-name.pipe';
import { PhoneFormatDirective } from './directives/phone-format.directive';

const modules = [
  MatToolbarModule,
  MatTabsModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  FormsModule,
  ReactiveFormsModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatPaginatorModule,
  MatSliderModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatSelectModule,
  MatTooltipModule,
  MatTableModule,
  MatListModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatDividerModule,
  MatMenuModule,
  MatSortModule,
  MatStepperModule,
  MatSnackBarModule,
  MatRippleModule,
  NgxPaginationModule,
  SwiperModule,
  MatSidenavModule,
  MatButtonToggleModule,
  MatBottomSheetModule,
  OverlayModule,
];

const pipes: any[] | import('@angular/core').Type<any> = [MonthNamePipe];
const sharedComponents: any[] | import('@angular/core').Type<any> = [
  DenInfoComponent,
  DeleteConfirmationComponent,
  NotFoundComponent,
  DenStandardTimeComponent,
  SearchPlaceholderListComponent,
  DefaultPlaceholderComponent,
  MenuBottomComponent,
  SnackbarAlertComponent,
  SetDurationComponent,
];
const directives: any[] | import('@angular/core').Type<any> = [
  PhoneNumberDirective,
  NoSpaceDirective,
  PhoneFormatDirective,
];

@NgModule({
  declarations: [pipes, directives, sharedComponents],
  imports: [CommonModule, modules, RouterModule],
  exports: [modules, pipes, sharedComponents, directives],
})
export class UtilMaterialModule {}
