import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[phoneFormat]',
})
export class PhoneFormatDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    // Get input value without non-numeric characters
    const inputVal = event.target.value.replace(/\D/g, '');

    // Check if the input value is not empty
    if (inputVal) {
      // Format the phone number
      const formattedNumber = this.formatPhoneNumber(inputVal);

      // Set the formatted value back to the input
      this.el.nativeElement.value = formattedNumber;
    }
  }

  private formatPhoneNumber(value: string): string {
    const areaCode = value.substring(0, 3);
    const middlePart = value.substring(3, 6);
    const lastPart = value.substring(6, 10);

    return `(${areaCode}) ${middlePart}-${lastPart}`;
  }
}
