
/* eslint-disable @typescript-eslint/no-empty-interface */
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { environment } from 'apps/den-app/src/environments/environment.prod';

export interface State {}

export const reducers: ActionReducerMap<State> = { router: routerReducer };

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];
