import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatInput } from '@angular/material/input';

@Directive({
  selector: '[appPhoneFormat]',
})
export class PhoneNumberDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  // @HostListener('input', ['$event']) onInput(event: Event) {
  //   const inputElement = this.el.nativeElement as HTMLInputElement;
  //   let phoneNumber = inputElement.value.replace(/\D/g, '');
  //   phoneNumber = phoneNumber.slice(0, 10);
  //   if (phoneNumber.length >= 3) {
  //     phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  //   }
  //   if (phoneNumber.length >= 9) {
  //     phoneNumber = `${phoneNumber.slice(0, 9)}-${phoneNumber.slice(9)}`;
  //   }

  //   inputElement.value = phoneNumber;
  // }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let phoneNumber = input.value.replace(/\D/g, ''); // Remove non-digit characters
    // Ensure the phone number does not exceed 10 digits
    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.slice(0, 10);
    }
    const formattedPhoneNumber = this.formatPhoneNumberUS(phoneNumber);
    this.renderer.setProperty(input, 'value', formattedPhoneNumber);
  }

  private formatPhoneNumberUS(phoneNumber: string): string {
    // Apply the US format (e.g., (123) 456-7890)
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
}
